import { LanguageSelectorProps } from '@wix/thunderbolt-components'
import { named, optional, withDependencies } from '@wix/thunderbolt-ioc'
import {
	Props,
	ViewMode,
	ViewModeSym,
	IPropsStore,
	IMultilingual,
	ICompEventsRegistrar,
	CompEventsRegistrarSym,
	PageFeatureConfigSymbol,
	SiteFeatureConfigSymbol,
} from '@wix/thunderbolt-symbols'
import { ComponentWillMount, ViewerComponent } from 'feature-components'
import { MultilingualSymbol } from 'feature-multilingual'
import { updateProps } from './languageSelectorCommon'
import { name } from './symbols'
import { LanguageSelectorPageConfig, LanguageSelectorSiteConfig } from './types'

const languageSelectorFactory = (
	siteFeatureConfig: LanguageSelectorSiteConfig,
	pageFeatureConfig: LanguageSelectorPageConfig,
	props: IPropsStore,
	compEventsRegistrar: ICompEventsRegistrar,
	viewMode: ViewMode,
	multilingual?: IMultilingual
): ComponentWillMount<ViewerComponent<LanguageSelectorProps>> => {
	return {
		componentTypes: ['LanguageSelector'],
		componentWillMount(languageSelectorComp) {
			if (!multilingual) {
				return
			}

			const originalProps = pageFeatureConfig.componentsMetaData.find(({ id }) => id === languageSelectorComp.id)

			if (!originalProps) {
				return
			}
			updateProps({
				siteFeatureConfig,
				componentsMetaData: [originalProps],
				props,
				isMobile: viewMode === 'mobile',
				currentLanguage: multilingual.currentLanguage,
			})

			compEventsRegistrar.register(languageSelectorComp.id, 'onChange', multilingual.setCurrentLanguage)
		},
	}
}

export const LanguageSelectorWillMount = withDependencies(
	[
		named(SiteFeatureConfigSymbol, name),
		named(PageFeatureConfigSymbol, name),
		Props,
		CompEventsRegistrarSym,
		ViewModeSym,
		optional(MultilingualSymbol),
	],
	languageSelectorFactory
)

import { Language, IPropsStore } from '@wix/thunderbolt-symbols'
import { LanguageSelectorSiteConfig, ItemFormat, IconType, DisplayMode, LanguageSelectorPageConfig } from './types'
import { utils } from '@wix/santa-multilingual/dist/languages' // eslint-disable-line no-restricted-syntax
import { LanguageSelectorItem, LanguageSelectorProps } from '@wix/thunderbolt-components'

const calculateItemText = (language: Language, itemFormat: ItemFormat, displayMode: DisplayMode, isMobile: boolean) => {
	if (itemFormat === 'shortName') {
		return language.languageCode.toUpperCase()
	} else if (itemFormat === 'fullName') {
		return language.name
	} else if (itemFormat === 'fullLocalizedName') {
		return language.localizedName
	} else if (itemFormat === 'iconOnly' && displayMode !== 'horizontal' && isMobile) {
		return language.name
	}
}

const moveMainLanguageToTop = (languages: LanguageSelectorProps['items'], originalLanguageCode: string) => {
	languages.unshift(
		languages.splice(
			languages.findIndex(({ value }) => value === originalLanguageCode),
			1
		)[0]
	)
}
const sortByLanguagesOrder = (languages: LanguageSelectorProps['items'], languagesOrder: Array<string>) => {
	languages.sort(
		(language1, language2) => languagesOrder.indexOf(language1.value) - languagesOrder.indexOf(language2.value)
	)
}

export const getItems = (
	siteFeatureConfig: LanguageSelectorSiteConfig,
	iconType: IconType,
	itemFormat: ItemFormat,
	languagesOrder: Array<string>,
	displayMode: DisplayMode,
	isMobile: boolean,
	currentLanguage: Language
): Array<LanguageSelectorItem> => {
	const { flagsUrl } = siteFeatureConfig

	const items = siteFeatureConfig.languages.map((language) => {
		const hasIcons = iconType && iconType !== 'none'
		return {
			text: calculateItemText(language, itemFormat, displayMode, isMobile),
			value: language.languageCode,
			selected: language.languageCode === currentLanguage.languageCode,
			label: language.name,
			flag: hasIcons ? utils.getFlagIconPng(flagsUrl, iconType, language.countryCode) : undefined,
			flagSrcSet: hasIcons ? utils.getFlagIconSrcSet(flagsUrl, iconType, language.countryCode) : undefined,
		}
	})

	if (languagesOrder.length) {
		sortByLanguagesOrder(items, languagesOrder)
	} else {
		moveMainLanguageToTop(items, siteFeatureConfig.originalLanguageCode)
	}

	return items
}

export const updateProps = ({
	siteFeatureConfig,
	componentsMetaData,
	props,
	isMobile,
	currentLanguage,
}: {
	siteFeatureConfig: LanguageSelectorSiteConfig
	componentsMetaData: LanguageSelectorPageConfig['componentsMetaData']
	props: IPropsStore
	isMobile: boolean
	currentLanguage: Language
}) => {
	const newProps = componentsMetaData.reduce<Record<string, Partial<LanguageSelectorProps>>>(
		(acc, { id, iconType, itemFormat, languagesOrder, displayMode }) => {
			acc[id] = {
				items: getItems(
					siteFeatureConfig,
					iconType,
					itemFormat,
					languagesOrder,
					displayMode,
					isMobile,
					currentLanguage
				),
				disabledReason: siteFeatureConfig.disabledReason,
			}
			return acc
		},
		{}
	)
	props.update(newProps)
}
